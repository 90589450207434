const seedsList = [
    {
        "id": "tohum1",
        "name": "Sebze Tohumları",
        "image": "https://cdn.dsmcdn.com/ty812/product/media/images/20230404/22/318313701/882211268/1/1_org_zoom.jpg",
        "info": ""
    },
    {
        "id": "tohum2",
        "name": "Yeşillik Tohumları",
        "image": "https://platincdn.com/4009/pictures/LDTJVVDJYV117202216555_100_frenk_kimyonu_sifali_ye.jpg",
        "info": ""
    },
]

export default seedsList; 